import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Importing components
import Navbar from './assets/components/Navbar.js';
import Jumbotron from './assets/components/Jumbotron.js';
import InformationSection from './assets/components/InfoSection.js';
import ImpactsSection from './assets/components/ImpactSection.js';
import Testimonials from './assets/components/Testimonials.js';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import ScrollToTop from './assets/components/scrollingFunction.js'
// Import all pages here
import AboutUsPage from './AboutUsPage.js'
import PrivacyPage from './PrivacyPolicyPage.js'; 
import InvestrorsPage from './InvestrorsPage.js'
import GynaecologyPage from './GynaecologyPage.js'
import MentalhealthPage from './MentalhealthPage.js'
import GPFamMedicinePage from './GPFamMedicinePage.js'
import SpecialistPage from './SpecialistPage.js'

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <div className="App">
        <Routes>                   
          <Route path="/privacy-policy" element={<PrivacyPage />} /> {/*Redirects like this*/}
          <Route path="/about-us" element={<AboutUsPage />} /> {/*Redirects like this*/}
          <Route path="/gp-fam-med" element={<GPFamMedicinePage />} /> {/*Redirects like this*/}
          <Route path="/Gynaecology" element={<GynaecologyPage />} /> {/*Redirects like this*/}
          <Route path="/Mentalhealth" element={<MentalhealthPage />} /> {/*Redirects like this*/}
          <Route path="/investors" element={<InvestrorsPage />} /> {/*Redirects like this*/} 
          <Route path="/specialists" element={<SpecialistPage />} /> {/*Redirects like this*/}
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}
function HomePage() {
  return (
    <>
      <Navbar />
      <Jumbotron />
      <InformationSection />
      <ImpactsSection />
      <Testimonials />
      <SignupComponent />
      <Footer />
      <Copyright />
    </>
  );
}

export default App;

//import AssistCareAI from './AssistCareAI.js'
//<Route path="/assist-careAI" element={<AssistCareAI />} /> {/*Redirects like this*/}
//import MedicalEducationPage from './MedicalEducationPage.js'
//<Route path="/medical-education" element={<MedicalEducationPage />} /> {/*Redirects like this*/}
//import AlliedHealthPage from './AlliedHealthPage.js'
//<Route path="/allied-health" element={<AlliedHealthPage />} /> {/*Redirects like this*/}
// /* */
