import React from 'react';
import keyStethImage from '../icons/Logo.jpg';

function GynaecologyInfo() {
    return (
        <div className="jumbotron-aboutUs">
            <div className="heading">
                <div className="content">
                    <strong><strong>MediSpeakAI has made a notable entrance into the Australian market and aims for rapid and sustained growth in the coming years. As we're ambitiously expanding, our sights are set not only on strengthening our foothold in Australia but also on a promising launch in India by December 1st, 2023. Beyond figures, MediSpeakAI embodies a dynamic vision, striving to redefine healthcare through advanced AI solutions.</strong></strong>
                </div>
                <div className="content">
                <p><strong><strong><strong>Key Features:</strong></strong></strong></p>
                    <p><strong><strong>Efficient Documentation:</strong></strong> <strong>Reduces documentation time by 50%, enabling healthcare professionals to focus more on patient care.</strong></p>
                    <p><strong><strong>Intelligent Assistance:</strong></strong> <strong>Assists in collecting patient history, providing insights for diagnosis, differential diagnosis, and management strategies.</strong></p>
                    <p><strong><strong>Rapid Clinical Documentation:</strong> </strong><strong>Utilizes an advanced large language model API to generate detailed clinical notes swiftly, which can be seamlessly integrated into electronic health records.</strong></p>
                    <p><strong><strong>Patient Education:</strong></strong> <strong>Offers the capability to create patient education handouts promptly, enhancing patient understanding and engagement.</strong></p>
                    <p><strong><strong><strong>Benefits:</strong></strong></strong></p>
                    <p><strong><strong>Time-Saving:</strong></strong> <strong>Significantly cuts down the time spent on documentation.</strong></p>
                    <p><strong><strong>Enhanced Clinical Support:</strong></strong> <strong>Delivers critical assistance in making clinical decisions and formulating treatment plans.</strong></p>
                    <p><strong><strong>Seamless Integration:</strong></strong> <strong>Effortlessly copies and pastes information into electronic health systems, streamlining the documentation process.</strong></p>
                    <p><strong><strong>Educational Tool:</strong></strong> <strong>Provides valuable educational material to patients, improving their healthcare experience.</strong></p>
                  </div>

            </div>
            <div className="youtube-player">
                <img
                    width="560"
                    height="315"
                    src={keyStethImage}
                    alt="YouTube video placeholder"
                    title="YouTube video player"
                    style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
        </div>
    );
}

export default GynaecologyInfo;
