import React from 'react';
import mikeIcon from '../icons/mike-icon.png';
import dataAnalysisIcon from '../icons/data-analysis-icon.png';
import diagnosticsIcon from '../icons/diagnostics-insights.png';
import recommendationsIcon from '../icons/reccomendations.jpeg';
import assistingIcon from '../icons/assisting-healthcare.png';

function InformationSection() {
  return (
    <div className="information-sections">
        <label className="question-working">How does MediSpeakAI work?</label>
        <div className="content-answer">MediSpeakAI operates at the intersection of cutting-edge speech recognition and sophisticated artificial intelligence. Through this intricate process, MediSpeakAI stands as a beacon of innovation in the healthcare sector, revolutionizing patient-doctor interactions and enhancing the quality and efficiency of care.</div>
        
        <div className="step-grid">
            <div className="step">
                <div className="logo-block"><img className="gridlogo" src={mikeIcon}></img></div>                
                <label className="question-working-sub">Voice Transcription</label>
                <div className="content-answer">When patients articulate their medical complaints or describe specific symptoms, the first layer of MediSpeakAI springs into action. Using state-of-the-art speech recognition algorithms, it flawlessly transcribes these verbal descriptions into precise textual data.</div>
            </div>
            
            <div className="step">
                <div className="logo-block"><img className="gridlogo" src={dataAnalysisIcon}></img></div>
                <label className="question-working-sub">Data Analysis</label>
                <div className="content-answer">Once the speech is transcribed, MediSpeakAI's core AI engine begins its work. It delves deep into the text, extracting relevant information and patterns from the patient's descriptions.</div>
            </div>
            
            <div className="step">
                <div className="logo-block"><img className="gridlogo" src={diagnosticsIcon}></img></div>
                <label className="question-working-sub">Diagnostic Insights</label>
                <div className="content-answer">Based on the analyzed data, MediSpeakAI provides potential diagnostic suggestions. These insights are drawn from vast medical databases, recent research, and known correlations between symptoms.</div>
            </div>
            
            <div className="step">
                <div className="logo-block"><img className="gridlogo" src={recommendationsIcon}></img></div>
                <label className="question-working-sub">Management Recommendations</label>
                <div className="content-answer">Beyond just diagnosis, the system further aids healthcare professionals by suggesting potential management strategies or treatments. It offers solutions that might be best suited to address the identified issues.</div>
            </div>
            
            <div className="step">
                <div className="logo-block"><img className="gridlogo" src={assistingIcon}></img></div>
                <label className="question-working-sub">Assisting Healthcare Providers</label>
                <div className="content-answer">The final output is a detailed report that assists doctors, nurses, and other healthcare professionals in making informed decisions. MediSpeakAI serves as a supplementary tool to augment human expertise.</div>
            </div>
            
        </div>
    </div>
  );
}

export default InformationSection;
