
import React from 'react';


function MentalhealthJumbo() {
    return (
        <div className="jumbotron">
            <div className="heading">
                <h1>Mental Health</h1>
                <p>Medispeak.ai is a state-of-the-art speech-to-text application tailored for mental health specialists. By capturing the nuanced dialogue of psychological consultations, it offers unparalleled support to Psychiatrists and Psychologists, enhancing the therapeutic experience.</p>
            </div>
        </div>
    );
}

export default MentalhealthJumbo;
