import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import medicalImagry from '../icons/medicalImagry.jpeg';
import voiceInte from '../icons/voiceInte.jpg';
import revClin from '../icons/revClin.jpg';
import medCommu from '../icons/medCommu.jpg';
import surgery from '../icons/surgery.jpeg';

function SpecialistWhatis() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="testimonials">
      <Slider {...settings}>        
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec" src={voiceInte} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h2>Voice-Driven Clinical Documentation:</h2> Automate patient encounters with efficient speech-to-text functionality right at the point of care.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec" src={revClin} alt="Revolutionizing Clinical Decisions"></img></div>
              <div className="text-block-spec">
                <ul><h2>Revolutionizing Clinical Decisions:</h2>
                  <li>Gain immediate entry to crucial patient notes for informed decision-making.</li>
                  <li>Cardiovascular and gastroenterology fields benefit from AI-enhanced diagnostic assistance.</li>
                  <li>Instant Clinical Information: Fetch evidence-based data swiftly, bypassing the need to sift through multiple websites.</li>
                </ul>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec" src={medCommu} alt="Optimizing Patient Communication"></img></div>
              <div className="text-block-spec">
                <ul><h2>Optimizing Patient Communication:</h2>
                  <li>Elevate patient discourse; MediSpeakAI captures every word.</li>
                  <li>Pediatric care made efficient – focus on the child while the AI handles documentation.</li>
                  <li>Navigate the nuances of women's health with organized screenings and specialized care notes.</li>
                </ul>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec" src={surgery} alt="Operational Mastery & Continuous Adaptation"></img></div>
              <div className="text-block-spec">
                <ul><h2>Operational Mastery & Continuous Adaptation:</h2>
                  <li>Reallocate time from documentation to direct patient engagement.</li>
                  <li>Stay abreast with the ever-advancing medical landscape through AI's perpetual learning.</li>
                  <li>Dive into meticulous documentation, ensuring minimal errors and unmatched data security.</li>
                </ul>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec" src={medicalImagry} alt="Empowering Medical Education"></img></div>
              <div className="text-block-spec">              
                <p><h2>Empowering Medical Education:</h2> MediSpeakAI acts as the bridge between medical trainees and advanced specialists, delivering invaluable insights.</p>
              </div>
            </div>
        </div>
      </Slider>
    </div>
  );
}

export default SpecialistWhatis;
