import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import SpecialistJumbo from './assets/components/SpecialistJumbo.js';
import SpecialistWhatis from './assets/components/SpecialistWhatis.js';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import SpecialistWhatis_four from './assets/components/SpecialistWhatis_four.js'
// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function SpecialistPage() {
  return (
    <div className="App">
      <Navbar />
      <SpecialistJumbo />
      <SpecialistWhatis />
      <SpecialistWhatis_four />
      <SignupComponent />
      <Footer />
      <Copyright />
    </div>
  );
}

export default SpecialistPage;

//   <SpecialistWhatis_two />
//import SpecialistWhatis_two from './assets/components/SpecialistWhatis_two.js';
//<SpecialistJumbo_two />
//import SpecialistJumbo_two from './assets/components/SpecialistJumbo_two.js'
//import SpecialistWhatis_three from './assets/components/SpecialistWhatis_three.js'
//<SpecialistWhatis_three />