import React from 'react';
import keyStethImage from '../icons/team.webp'

function AboutUsTeam() {
  return (
    <div className="jumbotron-ourTeam">   
        <div className="heading"><strong>Our Team</strong>
            <div className="content">
            <strong>MedispeakAI thrives on the brilliance of its eclectic crew - a symphony of medical virtuosos, AI wizards, and 
            visionary trailblazers. Our team's rich tapestry of knowledge ensures that we present a harmonious blend of 
            medical acumen and technological brilliance. Leading this transformative journey is Dr. Eshwar Madas, 
            an Australian-certified family physician with a flair for medical management. Fueled by his unwavering belief in AI's
            potential in healthcare, Dr. Madas, in tandem with our AI virtuoso, Mr. Rajeshwar Reddy Konkisa, has breathed life 
            into the MedispeakAI dream.</strong>
            </div>
        </div>  
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>                      
    </div>    
  );
}

export default AboutUsTeam;
