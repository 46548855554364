import React from 'react';
import keyStethImage from '../icons/missViss.jpeg'

function AboutUSMissionVision() {
  return (
    <div className="jumbotron-mv">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

        <div className="heading">Our Mission and Vision
            <div className="content">
            <strong>Mission Statement: </strong>"Redefining healthcare communication with AI-driven precision to uplift patient experiences and outcomes."
            </div>
            <div className="content">
            <strong>Vision Statement: </strong>"Steering the global AI-healthcare metamorphosis to a future where unparalleled, streamlined care becomes an everyday reality."
            </div>
        </div>        
    </div>    
  );
}

export default AboutUSMissionVision;
