import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../icons/Logo.jpg';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary navbar-styles">
        <div className="container-fluid">
            <Link to="/" className="navbar-brand">
                <img className="madas-health-logo" src={logo} alt="MediSpeak Logo" />
                <span className="logo-label">MediSpeak.ai</span>
            </Link>

            <button 
                className="navbar-toggler" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto"> 
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/about-us">About Us</Link>
                    </li>                   
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/gp-fam-med">GP/Family Medicine</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link custom-nav-link" to="/Gynaecology">Gynaecology</Link>
                      </li> 
                      <li className="nav-item">
                          <Link className="nav-link custom-nav-link" to="/MentalHealth">Mental Health</Link>
                      </li> 
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/specialists">Other Specialists</Link>
                      </li>

                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/investors">Investors</Link>
                    </li>     
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/others">Others <span className="dropdown-icon">▼</span></Link>
                        <div className="dropdown">
                            <Link className="dropdown-item" to="/speakSparkAI">SpeakSparkAI</Link>
                            <Link className="dropdown-item" to="/gourmentAI">GourmentAI</Link>
                            <Link className="dropdown-item" to="/accouting ">Accouting </Link>
                        </div>
                    </li>                        
                </ul>
            </div>
        </div>
    </nav>
  );
}

export default Navbar;

