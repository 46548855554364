import React from 'react';
import desDoc from '../icons/desDoc.jpeg';
import voiceIn from '../icons/voiceIn.webp';
import nlpIntegration from '../icons/nlpIntegration.png'
import opWF from '../icons/opWF.png'
import precDiag from '../icons/precDiag.jpg'
import invalInsight from '../icons/invalInsight.jpeg'
import depLess from '../icons/depLess.jpeg'
import medError from '../icons/medError.webp'
import medicoLeagal from '../icons/medicoLeagal.jpg'
import timeMoney from '../icons/timeMoney.jpg'
import betterPatient from '../icons/betterPatient.png'
import languageSupp from '../icons/languageSupp.jpg'
import intSearch from '../icons/intSearch.jpg'
import howWork from '../icons/howWork.png'
import logoImage from '../icons/Logo.jpg';

function GPFamInfo() {
    return (
        <div className="testimonials">
            <label className="question-working">Key Features</label>
           
            <div className="step-grid">
                <div className="step-edu">
                    <div className="left-section">
                        <img src={desDoc} alt="Designed by a Practitioner for Practitioners" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Designed by a Practitioner for Practitioners</label>
                        <div className="content-answer">Crafted by a General Practitioner ensuring an intimate understanding of clinical needs.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={voiceIn} alt="Exclusive Voice Interactive Technology" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Exclusive Voice Interactive Technology</label>
                        <div className="content-answer">Transcribe verbal interactions between patients and healthcare professionals seamlessly into text.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={nlpIntegration} alt="Advanced Language Model Integration" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Advanced Language Model Integration</label>
                        <div className="content-answer">Transform dialogues into detailed clinical documentation for better patient care.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={opWF} alt="Optimized Workflow" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Optimized Workflow</label>
                        <div className="content-answer">Enhance your productivity and streamline your day-to-day clinical processes.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={precDiag} alt="Precision in Diagnosis & Management" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Precision in Diagnosis & Management</label>
                        <div className="content-answer">Immediate, accurate, and comprehensive documentation of patient complaints.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={invalInsight} alt="Invaluable Insights" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Invaluable Insights</label>
                        <div className="content-answer">Leverage AI for diagnostic, investigative, and management guidance.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={depLess} alt="Depend Less on Memory" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Depend Less on Memory</label>
                        <div className="content-answer">Ensure consistent, evidence-based care without the pressure of recalling every detail.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={medError} alt="Minimize Medical Errors" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Minimize Medical Errors</label>
                        <div className="content-answer">Immediate documentation leads to more accurate patient care.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={medicoLeagal} alt="Enhanced Medico-legal Safety" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Enhanced Medico-legal Safety</label>
                        <div className="content-answer">Reduce risks with precision transcription and data-based actions.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={timeMoney} alt="Time is Money" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Time is Money</label>
                        <div className="content-answer">Spend more minutes with patients, and less on paperwork.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={betterPatient} alt="Better Patient Outcomes" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Better Patient Outcomes</label>
                        <div className="content-answer">Efficient diagnosis and management processes ensure optimal patient care.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={languageSupp} alt="Language Support" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Language Support</label>
                        <div className="content-answer">English-to-English transcription, with potential future expansions to accommodate other languages.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={intSearch} alt="Integrated Search Window" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">Integrated Search Window</label>
                        <div className="content-answer">Quickly access precise clinical information for on-the-spot decision making without toggling between multiple websites.</div>
                    </div>
                </div>

                <div className="step-edu">
                    <div className="left-section">
                        <img src={howWork} alt="How It Works" />
                    </div>
                    <div className="right-section">
                        <label className="question-working-sub">How It Works</label>
                        <div className="content-answer">Patients voice out their concerns, MediSpeakAI transcribes and analyzes, giving you the best clinical insights.</div>
                    </div>
                </div>
                
            </div>

            <div className="copyright-container-gp">
                <h3>Upgrade to MediSpeakAI</h3> 
                <p>Embrace the future of healthcare documentation and elevate your practice to new heights!</p>
            </div>            
        </div>
    );
}

export default GPFamInfo;
