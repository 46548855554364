import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import InvestorsJumbo from './assets/components/InvestorsJumbo.js'
import InvestorsInfo from './assets/components/InvestorsInfo.js'

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function InvestrorsPage() {
  return (
    <div className="App">
      <Navbar />
      <InvestorsJumbo />
      <InvestorsInfo />
      <SignupComponent />
      <Footer />
      <Copyright />
    </div>
  );
}

export default InvestrorsPage;
