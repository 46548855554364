import React from 'react';
import inputWindow from '../icons/inputWindow.jpg';
import guideWindow from '../icons/guideWindow.jpg';
import summaryWindow from '../icons/summaryWindow.jpg';
import MadasSir from '../icons/Madas.jpg'
import RajSir from '../icons/RajSir.webp'
import keyStethImage from '../icons/AI_team.webp'

function AboutUsTeamDetails() {
    return (
        <div className="testimonials">
            <input type="radio" name="testimonialSlide" id="slide1" className="slider__radio" defaultChecked />
            <input type="radio" name="testimonialSlide" id="slide2" className="slider__radio" />
            <input type="radio" name="testimonialSlide" id="slide3" className="slider__radio" />
          
            <div className="slider">            
                <div className="slider__inner">                    
                    <div className="slider__contents"><i className="slider__image fa fa-codepen"></i>
                        <div className="left-section-slider">
                            <img src={MadasSir} alt="Description of Image" />
                        </div>
                        <div className="right-section-slider">
                            <h2 className="slider__caption"><strong>Dr.Eshwar Madas</strong></h2>
                        <h5><strong>Founder and CEO </strong></h5>
                            <p className="slider__txt">With an extensive background in medical administration, clinical practice, and progressive healthcare technologies, Dr. Eshwar Madas is a distinguished figure in the healthcare sector. His qualifications include an MBBS, MD in Hospital Administration, a Postgraduate Diploma in Health Service Management, a Master of Business Studies in Healthcare, and a Fellowship from the esteemed Royal Australian College of General Practitioners (FRACGP).</p>                
                            <p className="slider__txt">Dr. Madas's proficiency in his field is built on a solid foundation of practical experience, having served in multiple high-profile roles across Australia and New Zealand. He held a position as a Trainee Registrar in Medical Administration with The Royal Australasian College of Medical Administrators (RACMA) and acted as a Regional Medical Director in Victoria. As Medical Director and Principal General Practitioner at the esteemed Lane Street Medical Centre in Sydney, he demonstrated exceptional leadership and an unwavering commitment to patient care.</p>
                            <p className="slider__txt">His commitment to medical education is equally profound, reflected in his contribution to the development of medical students and international medical graduates. Dr. Madas has been instrumental in driving health education and awareness initiatives, positioning him as a strong advocate for continued learning and patient engagement. Dr. Madas's interest in innovative technologies has steered his career toward the fusion of healthcare and technology. He has established his expertise in telehealth and electronic health records and demonstrated the transformative potential of artificial intelligence in healthcare delivery. His experience as a clinical systems consultant for 17 public hospitals in Northwest Victoria only underscores his influence, where he played a crucial role in enhancing healthcare delivery by improving critical hospital-specific IT systems. He has been pivotal in shaping functional specifications for diverse hospital-based clinical applications and successfully implementing health-specific IT projects.</p>
                        </div>                        
                    </div>
                    <div className="slider__contents"><i className="slider__image fa fa-codepen"></i>
                        <div className="left-section-slider">
                            <img src={RajSir} alt="Description of Image" />
                        </div>
                        <div className="right-section-slider">
                            <h2 className="slider__caption"><strong>Rajeshwar Reddy Konkisa</strong></h2>
                            <h5><strong>Chief Technology Officer & Director of AI & Data Science</strong></h5>
                            <p className="slider__txt">Rajeshwar is a highly experienced AI Specialist and Senior Data Scientist, boasting a remarkable 20 years of expertise in the IT and Data Science fields. He holds an impressive educational background, including a <strong> MicroMasters in Statistics and Data Science from Massachusetts Institute of Technology,Boston USA </strong>, a Post Graduation in Data Science from the Indian Institute of Technology Guwahati, a Bachelor of Engineering in Computer Technology from Kavikulguru Institute of Technology and Science at Nagpur University in India, and a Computer Engineering Diploma from S.N.M Polytechnic Khammam, India.</p>                
                            <p className="slider__txt">Throughout his career, he has collaborated with various clients from India, Australia, and the United States on healthcare projects. His expertise is in Classification, Anomaly Detection, and Prediction, and he possesses hands-on experience in ETL, Python, Machine Learning/Deep Learning, AWS SageMaker, Machine Learning on AWS, Tensorflow, and the Hadoop toolset including Spark/Scala, Pyspark, Hive, and Kafka.</p>
                            <p className="slider__txt">One of Rajeshwar's notable strengths is his leadership ability, particularly in leading teams involved in data engineering, feature engineering, and model development. He is adept at utilizing advanced modeling techniques in AI/ML and Deep Learning. Moreover, Rajeshwar has a proven track record of deploying sophisticated modeling solutions in production environments. He actively participates in decision-making processes, contributing his insights on aspects such as product development and cost estimation.</p>
                        </div>                        
                    </div>
                    <div className="slider__contents"><i className="slider__image fa fa-codepen"></i>
                        <div className="left-section-slider">
                            <img src={keyStethImage} alt="Description of Image" />
                        </div>
                        <div className="right-section-slider">
                            <h5 className="slider__caption">Our team at Madas Health AI comprises of a diverse group of professionals, each bringing unique expertise and skill sets to contribute to our collective goals. Our team includes:</h5>
                            <ul>
                                <li>Chief Data Scientist</li>
                                <li>AI Specialists</li>
                                <li>Cybersecurity Consultants</li>
                                <li>Software Developers/Engineers</li>
                                <li>Quality Analysts</li>
                                <li>Project Manager</li>
                            </ul>              
                        </div>                        
                    </div>                    
                </div>
            </div>

            <div className="slider__controls">
                <label htmlFor="slide1" className="slider__label"></label>
                <label htmlFor="slide2" className="slider__label"></label>
                <label htmlFor="slide3" className="slider__label"></label>                
            </div>
        </div>
    );
}

export default AboutUsTeamDetails;
