import React from 'react';
import keyStethImage from '../icons/Logo.jpg'

function AboutUsJumbo() {
    return (
        <div className="jumbotron-aboutUs">
            <div className="heading">
                <strong>About Us</strong>
                <div className="content">
                    <strong>
                       Welcome to MedispeakAI, the pioneering force reshaping healthcare through the fusion of human intellect and cutting-edge AI technology. Born from the dynamic tech landscape of Sydney and launched in 2023, we stand as a testament to innovation in both medical practice and education.
                    </strong>
                </div>

                <div className="content">
                    <strong>
                        <p>As the esteemed descendant of  <strong><a href="https://madashealth.ai/">MadasHealthAI</a></strong>,we carry forward a legacy of pioneering breakthroughs.
                            Our platform effortlessly combines user-friendly design with the formidable capabilities of AI, delivering swift and accurate insights while dismantling age-old barriers to accessing medical expertise.</p>

                        <p>MedispeakAI is not just a digital innovation; we are a guiding light, illuminating the path toward a smarter, more efficient, and more accessible future in healthcare.</p>
                    </strong>
                </div>


                <div className="content">
                    <strong>
                        <strong>MediSpeakAI Clinical Trial Evaluation in Sydney, Australia.</strong>
                        <br />
                        Trial Period: July-December 2023.
                        <br />
                        Participants: 50 General Practitioners and 12 International Medical Graduates.
                        <br />
                        Methodology: Tested in real-time clinical scenarios.
                        <br /><br />
                        Key Findings:
                        <br />
                        Time Savings: An average of 7.5 minutes saved per consultation.
                        <br />
                        Diagnostic Accuracy: Above 95% success rate.
                        <br />
                        Economic Benefits: Significant time savings, improved patient care, and potential for increased efficiency.
                    </strong>
                </div>

                <div className="content">
                    <strong>
                        <strong>MediSpeakAI Clinical Trial Evaluation in Hyderabad, India.</strong>
                        <br />
                        Trial Participants: Over 105 doctors.
                        <br />
                        Duration: 5-week clinical trial.
                        <br />
                        100% Endorsement: Unanimous approval from clinicians.
                        <br /><br />
                        Impact on Diagnostic Accuracy: Highly praised for precise diagnostics.
                        <br />
                        Enhanced Clinical Documentation: Noted for comprehensive and efficient documentation.
                        <br />
                        Time Efficiency: Significant time savings across all medical professional levels.
                        <br />
                        Educational Value for Interns: Aids in history taking, targeted investigations, and accurate diagnoses.
                        <br /><br />
                        Support for Junior Trainees: Provides essential information on differential diagnoses and management, reducing the need for senior consultations.
                        <br />
                        Benefit for Senior Doctors: Confirms diagnostic theories and optimizes documentation time.
                        <br />
                        Reduction in Medical Errors: Notably decreases errors and unnecessary investigations, especially beneficial for junior doctors.
                        <br />
                        Ideal for Developing Countries: Particularly effective in settings like India, offering substantial economic and social benefits.
                    </strong>
                </div>
            </div>

            <div className="youtube-player">
                <img
                    width="560"
                    height="315"
                    src={keyStethImage}
                    alt="YouTube video placeholder"
                    title="YouTube video player"
                    style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
        </div>
    );
}


export default AboutUsJumbo;
