import React from 'react';

function MedicalEduConc() {
    return (
        <div className="jumbotron-aboutUs">
            <div className="heading">
                <div className="content">
                   
                    <div className="heading-med"><strong>Other Specialities</strong></div>

                    <strong><span style={{ fontWeight: 'bold' }}>Cardiologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Cardiologists specialize in the diagnosis and treatment of heart-related conditions. They assess and manage a range of cardiovascular issues, including heart attacks, heart failure, and arrhythmias.
                        Utilizing various diagnostic tools like EKGs and echocardiograms, cardiologists play a crucial role in promoting heart health and preventing cardiovascular diseases.
                    </strong>
                </div>

                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Orthopedic Surgeon:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Orthopedic surgeons focus on the musculoskeletal system, treating conditions affecting bones, joints, muscles, ligaments, and tendons.
                        They perform surgeries such as joint replacements, fracture repairs, and spinal procedures. Additionally, orthopedic surgeons work on rehabilitation plans to help patients
                        recover mobility and functionality after injuries or surgeries.
                    </strong>
                </div>
                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Pulmonologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Pulmonologists specialize in the respiratory system, diagnosing and treating diseases like asthma, chronic obstructive pulmonary disease (COPD), and pneumonia.
                        They often conduct pulmonary function tests to assess lung capacity and efficiency. Pulmonologists play a vital role in helping patients manage respiratory conditions and improve their overall lung health.
                    </strong>
                </div>
                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Neurologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Neurologists specialize in disorders of the nervous system, including the brain, spinal cord, and peripheral nerves.
                        They diagnose and treat conditions like epilepsy, multiple sclerosis, and migraines. Neurologists may use imaging studies, such as MRIs and CT scans, to identify abnormalities
                        in the nervous system and develop treatment plans to manage neurological disorders.
                    </strong>
                </div>
                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Gastroenterologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Gastroenterologists focus on the digestive system, addressing issues related to the stomach, intestines, liver, and pancreas.
                        They diagnose and treat conditions such as irritable bowel syndrome (IBS), inflammatory bowel disease (IBD), and liver diseases. Gastroenterologists often
                        perform procedures like endoscopies and colonoscopies for both diagnosis and treatment.
                    </strong>
                </div>
                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Endocrinologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Endocrinologists specialize in the endocrine system, which includes glands that produce hormones. They diagnose and manage conditions such as diabetes, thyroid disorders, and hormonal imbalances.
                        Endocrinologists play a crucial role in helping patients maintain hormonal balance through medication, lifestyle changes, and other interventions.
                    </strong>
                </div>
                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Ophthalmologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Ophthalmologists are experts in eye care, diagnosing and treating conditions affecting vision and eye health.
                        They perform surgeries like cataract removal, laser eye procedures, and treat disorders such as glaucoma and macular degeneration. Ophthalmologists also prescribe corrective lenses and provide comprehensive eye care.
                    </strong>
                </div>
                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Dermatologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Dermatologists specialize in skin health, diagnosing and treating conditions such as acne, eczema, and skin cancer.
                        They perform skin examinations, biopsies, and various cosmetic procedures. Dermatologists help patients maintain healthy skin through medical interventions, lifestyle recommendations, and skincare routines.
                    </strong>
                </div>
                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Rheumatologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Rheumatologists focus on autoimmune and inflammatory disorders affecting joints and connective tissues. They diagnose and treat conditions like rheumatoid arthritis, lupus, and gout.
                        Rheumatologists use a combination of medications, physical therapy, and lifestyle modifications to manage symptoms and improve the quality of life for patients.
                    </strong>
                </div>
                <div className="content">
                    <strong><span style={{ fontWeight: 'bold' }}>Nephrologist:</span></strong>
                    <br>
                    </br>
                    <strong>
                        Nephrologists specialize in kidney health, diagnosing and managing conditions such as chronic kidney disease, kidney stones, and hypertension-related kidney issues.
                        They play a key role in kidney transplantation and dialysis treatment. Nephrologists work to maintain optimal kidney function and overall renal health in their patients.
                    </strong>
                </div>
                <div className="content">
                    <strong>Interested Doctors are encouraged to reach out via admin@medispeak.ai to join us on this transformative journey.</strong>
                </div>
            </div>
          
        </div>
    );
}

export default MedicalEduConc;