import React from 'react';
import logoImage from '../icons/Logo.jpg';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer">
        <div className="brand-footer">                          
            <div className="youtube-player-footerr">            
                <img 
                    width="560" 
                    height="315" 
                    src={logoImage} 
                    alt="YouTube video placeholder" 
                    title="YouTube video player" 
                    style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
                />
            </div>
        </div>
        
        <div className="useful-links-footer">                
            <label className="question-working-sub">Useful Links</label>
            <div className="link-footer"><Link to="/privacy-policy" >Privacy Policy</Link></div>
            <div className="link-footer">Demo</div>
            <div className="link-footer">Download</div>            
        </div>
        
        <div className="contact-footer">              
            <div className="content-answer-footer">
                <label className="Contact-values"><i className="fas fa-map-marker-alt"></i> Address</label>
                <h4 className="values">Bella Vista, New South Wales, Australia</h4>
                <label className="Contact-values"><i className="fas fa-phone"></i> Phone</label>
                <h4 className="values">+61426244089</h4>
                <label className="Contact-values"><i className="fas fa-envelope"></i> Email</label>
                <h4 className="values">admin@medispeak.ai</h4>
            </div>
        </div>
    </div>    
  );
}

export default Footer;
