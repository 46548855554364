import React from 'react';
import keyStethImage from '../icons/Journey.jpg'

function AboutUsOurJourney() {
  return (
    <div className="jumbotron-aboutUs">
        <div className="heading"><strong>Our Journey</strong>
            <div className="content">
            <strong>From its genesis in 2023, MedispeakAI has enraptured the global medical fraternity. Initially embraced by a 
            kaleidoscope of international medical graduates, Australian general practitioners, and budding medical scholars, 
            the accolades and intrigue have been overwhelming. A testament to our budding legacy is our iconic three-window 
            system, revered for its user-centric design and impeccable accuracy. With foundations deeply rooted in revered 
            medical literature, MedispeakAI's AI radiates unmatched precision, earmarking us as the vanguard of tomorrow's 
            medical innovations. The horizon beckons, and with it, MedispeakAI's promise of reimagining medical landscapes.</strong>
            </div>
        </div>
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
    </div>
  );
}

export default AboutUsOurJourney;
