
import React from 'react';


function GynaecologyJumbo() {
    return (
        <div className="jumbotron">
            <div className="heading">
                <h1>Obstetrics & Gynaecology and Women’s Health</h1>
                <p>Medispeak.ai is a trailblazing application, designed by seasoned clinicians specifically for Obstetricians, Gynecologists, and Women's Health practitioners in Australia. This innovative tool transforms verbal interactions between healthcare providers and patients into precise textual data, leveraging exclusive voice interactive technology.</p>
            </div>
        </div>
    );
}

export default GynaecologyJumbo;
