import React from 'react';
import keyStethImage from '../icons/Logo.jpg'

function InvestorsInfo() {
  return (
    <div className="jumbotron-aboutUs">   
        <div className="heading">
            <div className="content">
                <strong>MediSpeakAI has made a notable entrance into the Australian market and aims for rapid and sustained growth in the coming years. As we're ambitiously expanding, our sights are set not only on strengthening our foothold in Australia but also on a promising launch in India by December 1st, 2023. Beyond figures, MediSpeakAI embodies a dynamic vision, striving to redefine healthcare through advanced AI solutions. </strong>
            </div>
            <div className="content">
                <strong>Interested investors are encouraged to reach out via admin@medispeak.ai to join us on this transformative journey.</strong>
            </div>
        </div>  
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>                              
    </div>    
  );
}

export default InvestorsInfo;
