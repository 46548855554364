import React from 'react';

function ImpactsSection() {
  return (
    <div className="impacts-sections">
        <label className="question-working-impacts">Impacts and Benefits of MediSpeakAI</label>
        
        <div className="step-grid-impact">
            <div className="step-impact">                          
                <label className="question-working-sub">Minimization of Medical Errors</label>
                <div className="content-answer">Ensures precise, real-time documentation, eliminating transcription inaccuracies.</div>
            </div>
            
            <div className="step-impact">                
                <label className="question-working-sub">Reduction in Medico-Legal Risks</label>
                <div className="content-answer">Digital accuracy guards against potential lawsuits and documentation discrepancies.</div>
            </div>
            
            <div className="step-impact">                
                <label className="question-working-sub">Enhanced Time Management for Clinicians</label>
                <div className="content-answer">Automates admin tasks, directing focus to direct patient interaction.</div>
            </div>
            
            <div className="step-impact">                
                <label className="question-working-sub">Improvement in Patient Outcomes</label>
                <div className="content-answer">Offers actionable insights for personalized, effective treatment decisions.
                </div>
            </div>  
            <div className="step-impact">                
                <label className="question-working-sub">MediSpeakAI is tax deductible</label>
                <div className="content-answer">Users can subtract its cost from their taxable income, potentially reducing the amount of taxes they owe.
                </div>
            </div>        
        </div>
    </div>
  );
}

export default ImpactsSection;
