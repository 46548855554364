import React from 'react';
import keyStethImage from '../icons/Logo.jpg';

function MentalhealthInfo() {
    return (
        <div className="jumbotron-aboutUs">
            <div className="heading">
                <div className="content">
                    <strong><strong>Medispeak.ai revolutionizes mental health care documentation, empowering professionals to focus on what they do best caring for their patients. With Medispeak.ai, embrace the future of mental health practice, where technology enhances human connection, not replaces it.</strong></strong>
                </div>
                <div className="content">
                <p><strong><strong><strong>Key Features:</strong></strong></strong></p>
                    <p><strong><strong>Accurate Record Keeping:</strong></strong> <strong>Medispeak.ai precisely transcribes the detailed and often nuanced conversations between mental health professionals and their patients, ensuring no critical information is missed.</strong></p>
                    <p><strong><strong>Enhanced Patient Engagement:</strong></strong> <strong>By minimizing the distraction of note-taking, therapists can maintain deeper, more empathetic connections with their patients, fostering a trusting therapeutic environment.</strong></p>
                    <p><strong><strong>Efficiency and Time-Saving:</strong> </strong><strong>Eliminates the burden of post-session documentation, allowing specialists to dedicate more time to patient care and less to paperwork. Remarkably, Medispeak.ai can save up to 50% of clinical documentation time.</strong></p>
                    <p><strong><strong>Consistency in Care:</strong></strong> <strong>Ensures consistency in patient records, improving the continuity of care and facilitating collaboration among healthcare providers.</strong></p>
                    <p><strong><strong><strong>Benefits:</strong></strong></strong></p>
                    <p><strong><strong>Improved Clinical Outcomes:</strong></strong> <strong>By capturing comprehensive dialogue details, Medispeak.ai aids in the accurate assessment and monitoring of patient progress, leading to more informed treatment decisions.</strong></p>
                    <p><strong><strong>Data Security:</strong></strong> <strong>Utilizes advanced encryption and security measures to protect sensitive patient information, adhering to confidentiality standards.</strong></p>
                    <p><strong><strong>Integration and Accessibility:</strong></strong> <strong>Easily integrates with existing EHR systems, providing immediate access to patient transcripts, which can be reviewed or shared with a few clicks.</strong></p>
                    <p><strong><strong>Support for Remote Consultations:</strong></strong> <strong>In an era of increasing telehealth, Medispeak.ai seamlessly transcribes remote consultations, ensuring quality documentation regardless of the setting.</strong></p>
                  </div>

            </div>
            <div className="youtube-player">
                <img
                    width="560"
                    height="315"
                    src={keyStethImage}
                    alt="YouTube video placeholder"
                    title="YouTube video player"
                    style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
        </div>
    );
}

export default MentalhealthInfo;
