import React from 'react';
import keyStethImage from '../icons/keySteth.jpg';  // Import the image based on the given directory structure

function Jumbotron() {
  return (
    <div className="jumbotron">
          <div className="heading"><strong>MediSpeakAI:</strong> Leading the Revolution in AI-Powered Transcription of Doctor-Patient Conversations, Developed by Madas Telehealth Pty Ltd.
              Explore Our Website for More AI Solutions from Madas Telehealth Pty Ltd.
              
              
              <p style={{ fontSize: '30px' }}>As the proud offspring of <strong><a href="https://madashealth.ai/">MadasHealthAI</a></strong>, we inherit a legacy of groundbreaking advancements. Our platform seamlessly integrates intuitive design with the power of AI, offering quick, precise insights and breaking down longstanding obstacles in accessing medical knowledge.</p>
              
               
             
            {/*<div className="content">
                Madas Telehealth Pty Ltd has created Medispeak.ai, a pioneering application developed by a practicing General Practitioner for use by fellow healthcare practitioners in Australia. This ground-breaking tool revolutionizes healthcare services by transcribing verbal exchanges between healthcare professionals and patients into textual data using our exclusive voice interactive technology. Following the transcription process, an advanced large language model API is utilized to convert these dialogues into comprehensive clinical documentation.
            </div> 
            <div className="content">
                This innovative solution significantly assists healthcare professionals by optimizing their workflow processes and boosting their productivity levels.
            </div>*/}
        </div>
        {/*<div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>*/}
    </div>
  );
}

export default Jumbotron;

